import { render, staticRenderFns } from "./ProductPage.vue?vue&type=template&id=3034cf32&"
import script from "./ProductPage.vue?vue&type=script&lang=js&"
export * from "./ProductPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadCrumbs: require('/~/src/modules/opus-ecom/components/BreadCrumbs.vue').default,OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,ProductImageGallery: require('/~/src/modules/opus-ecom/components/Catalog/ProductImageGallery.vue').default,OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,ProductReview: require('/~/src/modules/opus-ecom/components/ProductReview.vue').default,EstimatedShipping: require('/~/src/components/EstimatedShipping.vue').default,ProtectionLevel: require('/~/src/components/ProtectionLevel.vue').default,TierPrice: require('/~/src/components/TierPrice.vue').default,AddToCart: require('/~/src/modules/opus-ecom/components/Catalog/AddToCart.vue').default,CrossProducts: require('/~/src/modules/opus-ecom/components/Catalog/CrossProducts.vue').default,TukkuBenefits: require('/~/src/modules/mobiilitukku/components/TukkuBenefits.vue').default,OpusAccordionItem: require('/~/src/modules/opus/components/AccordionItem.vue').default,OpusAccordion: require('/~/src/modules/opus/components/Accordion.vue').default,ContentfulProductPage: require('/~/src/modules/contentful/components/ProductPage.vue').default,UpsyProduct: require('/~/src/components/UpsyProduct.vue').default,ProductPageSkeleton: require('/~/src/modules/opus-ecom/components/ProductPageSkeleton.vue').default})
